<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle :icon="IconLocation" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>
      <ObiTitle class="mb-3" color="primary" :title="$t('form_title')" />

      <ObiFloatingInput
         :label="$t('fields.name')"
         :disabled="isLoading"
         :placeholder="$t('fields.name')"
         v-model="formDataLocation.name"
         :errors="formDataLocation.errors.get('name')"
      />

      <ObiFloatingInput
         :label="$t('fields.country')"
         :disabled="isLoading"
         :placeholder="$t('fields.country')"
         v-model="formDataLocation.country"
         :errors="formDataLocation.errors.get('country')"
      />

      <ObiFloatingInput
         :label="$t('fields.city')"
         :disabled="isLoading"
         :placeholder="$t('fields.city')"
         v-model="formDataLocation.city"
         :errors="formDataLocation.errors.get('city')"
      />

      <ObiFloatingInput
         :label="$t('fields.district')"
         :disabled="isLoading"
         :placeholder="$t('fields.district')"
         v-model="formDataLocation.district"
         :errors="formDataLocation.errors.get('district')"
      />

      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('actions.save')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import PanelLocationApi from '@/api/PanelLocationApi';

import IconLocation from '@/components/Icons/general/IconLocation';

import formDataLocation from '@/views/pages/Location/FormData/form-data-location';

import PaginationResponse from '@/api/DTO/PaginationResponse';
import Portal from '@/api/Models/Portal';
import PanelPortalApi from '@/api/PanelPortalApi';

export default {
   name: 'ModalLocationCreate',
   i18n: {
      messages: {
         tr: {
            title: 'Konum Ekle',
            subtitle: 'Aşağıdaki bilgileri girerek yeni konum ekleyin',
            form_title: 'Lokasyon Bilgileri',
            fields: {
               name: 'Konum Adı',
               country: 'Ülke',
               city: 'Şehir',
               district: 'İlçe',
               portal: 'Portal',
            },
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Add Location',
            subtitle: 'Add a new location by entering the information below',
            form_title: 'Location Information',
            fields: {
               name: 'Location Name',
               country: 'Country',
               city: 'City',
               district: 'District',
               portal: 'Portal',
            },
            actions: {
               save: 'Save',
            },
         },
      },
   },

   data() {
      return {
         IconLocation,
         isLoading: false,
         formDataLocation,
         dataPortals: PaginationResponse.create().map(Portal),
      };
   },

   computed: {
      portalSelectOptions() {
         return this.dataPortals
            .get('data')
            .map((location) => ({ label: location.getName(), value: location.getId() }));
      },
   },
   async mounted() {
      await this.loadPortals();
   },

   methods: {
      show() {
         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      async submitForm() {
         if (!this.formDataLocation.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const response = await PanelLocationApi.store(this.formDataLocation.toObject());

            this.formDataLocation.reset();

            this.hide();
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },

      async loadPortals() {
         this.isLoading = true;

         try {
            this.dataPortals.merge(await PanelPortalApi.index({ per_page: 999 }));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
   },
};
</script>
